<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="cont">
      <div class="about-us">Driver Qualifications</div>
      <a-row :gutter="[24, 24]">
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/phone.svg" alt="">
            Smartphone
            <div class="mask">
              <span>IOS or Android, able to use Apple Store or Google Play Store to download our driver app.</span>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/license.svg" alt="">
            Valid Driver's License
            <div class="mask">
              <span>DMV issued, should not extend expiration date.</span>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/vehicle.svg" alt="">
            Vehicle in fair condition
            <div class="mask">
              <span>For instance, vehicle runs properly without significant mechanical issues; brakes, headlights, turn
                signals,
                and seat belts, should be operational.</span>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/clean.svg" alt="">
            Clean Driving Record
            <div class="mask">
              <span> Visit local DMV where your driver's license was issued or go to the Department of Motor Vehicles
                (DMV) website, looking for a section on the website that provides information about driving records or
                driver history.
              </span>
            </div>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/speak.svg" alt="">
            Read and speak English
          </div>
        </a-col>
        <a-col :span="8">
          <div class="item">
            <img src="../../../assets/images/driver/dui.svg" alt="">
            At least 23 years of age
          </div>
        </a-col>
      </a-row>
      <a-typography-title>
        Sign up
      </a-typography-title>
      <a-form :model="signForm" autoComplete="off" layout="vertical" ref="signRef" @submit="handleSubmit">
        <a-row :gutter="[20]">
          <a-col :span="12"><a-form-item field="firstname" label="First Name" :rules="[
            { required: true, message: 'Please enter firstname.' }
          ]" :validate-trigger="['blur']">
              <a-input v-model="signForm.firstname" placeholder="Please enter" autocomplete="off" />
            </a-form-item></a-col>
          <a-col :span="12"><a-form-item field="lastname" label="Last Name" :rules="[
            { required: true, message: 'Please enter lastname.' }
          ]" :validate-trigger="['blur']">
              <a-input v-model="signForm.lastname" placeholder="Please enter" autocomplete="off" />
            </a-form-item></a-col>
          <a-col :span="12"> <a-form-item field="email" label="Email" :rules="[
            { required: true, message: 'Please enter email.' },
            { match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Please enter correct email format.' }
          ]" :validate-trigger="['blur']">
              <a-input v-model="signForm.email" placeholder="Please enter" autocomplete="off" />
            </a-form-item></a-col>
          <a-col :span="12"> <a-form-item field="phone" label="Phone" :rules="[
            { required: true, message: 'Phone is required.' },
            { minLength: 10, maxLength: 15, message: '10-15 characters' }
          ]" :validate-trigger="['blur']">
              <a-input v-model="signForm.phone" placeholder="Required 5-10 characters" />
            </a-form-item></a-col>
        </a-row>
        <a-form-item field="isAgree" :rules="rules.isAgree">
          <a-checkbox v-model="signForm.isAgree">I agree to allow my phone number to be used for calls or texts regarding
            my application.
            Message & data rates may apply.</a-checkbox>
        </a-form-item>
        <a-form-item>
          <a-button html-type="submit" type="primary" :loading="loading">Submit</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { contact } from '@/api/homepage.js'
import { Notification } from '@arco-design/web-vue'
const signForm = reactive({
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  isAgree: false
})
const rules = {
  isAgree: [
    {
      validator: (value, cd) => {
        console.log(value)
        if (!value) {
          cd(new Error('Please agree.').message)
        } else {
          cd()
        }
      }
    }
  ]
}
const loading = ref(false)
const signRef = ref(null)
const handleSubmit = async ({ values, errors }) => {
  // console.log('values:', values, '\nerrors:', errors)
  if (!errors) {
    console.log('通过')
    loading.value = true
    const msg = await contact({
      name: signForm.firstname + ' ' + signForm.lastname,
      company: '',
      email: signForm.email,
      phone: signForm.phone,
      address: '',
      hear_us: '',
      message: 'Driver sign up.'
    })
    if (msg.code === 0) {
      loading.value = false
      Notification.success({
        title: 'Success',
        content: msg.message
      })
      signRef.value.resetFields()
    } else {
      loading.value = false
      Notification.error(msg.message)
    }
  }
}

</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../../assets/images/home/cangku2.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.cont {
  width: 1300px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  line-height: 1.8;

  .about-us {
    text-align: center;
    padding-top: 60px;
    font-weight: 300;
    font-size: 56px;
    line-height: 53px;
    color: #333333;
    margin-bottom: 60px;
  }
}

.item {
  border-radius: 8px;
  height: 180px;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-text-3);
  font-size: 16px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  position: relative;

  img {
    width: 60px;
    padding-bottom: 26px;
  }

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(var(--primary-5));
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
    color: #fff;

    span {
      padding: 12px;
    }
  }
}

.item:hover {
  // color: rgb(var(--primary-6));
  cursor: pointer;
  overflow: hidden;

  .mask {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
